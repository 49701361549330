import React, { MouseEventHandler } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import {
  ChevronLeft,
  ChevronRight,
  OpenInNew,
  OpenInNewOutlined,
  OpenInNewRounded,
} from '@mui/icons-material'
import { paletteSecondary } from '../colors'
import { darken, lighten, Theme } from '@mui/material/styles'

export const getButtonStyles =
  (type: BashButtonType) =>
  (theme: Theme): SxProps => {
    const baseStyles = {
      background: 'transparent',
      transition: 'background-color 0.2s ease',
    }

    const styleMap: Record<BashButtonType, SxProps> = {
      [BashButtonType.PRIMARY]: {
        ...baseStyles,
        background: theme.palette.primary.main,
        color: 'white',
        '&:hover': {
          background: darken(theme.palette.primary.main, 0.2),
        },
      },
      [BashButtonType.PRIMARY_NO_THEME]: {
        ...baseStyles,
        background: '#2E60DC',
        color: 'white',
        '&:hover': {
          background: darken('#2E60DC', 0.2),
        },
      },
      [BashButtonType.PRIMARY_LIGHT]: {
        ...baseStyles,
        background:
          theme.extras?.name === 'custom'
            ? theme.palette.grey[100]
            : theme.palette.primary[100],
        color:
          theme.extras?.name === 'custom'
            ? theme.palette.text.primary
            : theme.palette.primary[800],
        '&:hover': {
          background:
            theme.extras?.name === 'custom'
              ? theme.palette.grey[300]
              : theme.palette.primary[200],
          // : lighten(theme.palette.primary.main, 0.6),
        },
      },
      [BashButtonType.PRIMARY_LIGHT_NO_THEME]: {
        ...baseStyles,
        background: '#F2F5FD',
        color: '#2E60DC',
        '&:hover': {
          background: darken('#F2F5FD', 0.2),
        },
      },
      [BashButtonType.PRIMARY_LIGHT_BORDERED]: {
        ...baseStyles,
        background:
          theme.extras?.name === 'custom'
            ? theme.palette.grey[100]
            : theme.palette.primary[100],
        color:
          theme.extras?.name === 'custom'
            ? theme.palette.primary.main
            : theme.palette.primary[800],
        border: `1px solid ${darken(theme.palette.primary.main, 0.2)}`,
        '&:hover': {
          background: lighten(theme.palette.primary.main, 0.6),
        },
      },
      [BashButtonType.SECONDARY]: {
        ...baseStyles,
        background:
          theme.extras.name === 'dark'
            ? theme.palette.secondary[100]
            : theme.palette.secondary[800],
        color: 'white',
        '&:hover': {
          background: paletteSecondary.light[950],
        },
      },
      [BashButtonType.SECONDARY_DARK]: {
        ...baseStyles,
        background:
          theme.palette.mode === 'dark'
            ? theme.palette.secondary[100]
            : theme.palette.secondary[950],
        color: 'white',
      },
      [BashButtonType.SECONDARY_LIGHT]: {
        ...baseStyles,
        background: theme.palette.secondary[100],
        color: theme.palette.secondary[800],
        '&:hover': {
          background: theme.palette.secondary[200],
        },
      },
      [BashButtonType.CLEAR]: {
        ...baseStyles,
        color: 'black',
        '&:hover': {
          background: theme.palette.grey[200],
        },
      },
      [BashButtonType.CLEAR_GREY]: {
        ...baseStyles,
        color: theme.palette.grey[800],
        '&:hover': {
          background: theme.palette.grey[200],
        },
      },
      [BashButtonType.CLEAR_PRIMARY]: {
        ...baseStyles,
        color: theme.palette.primary.main,
        '&:hover': {
          background: theme.palette.primary[100],
        },
      },
      [BashButtonType.CLEAR_SECONDARY]: {
        ...baseStyles,
        color:
          theme.extras?.name === 'custom'
            ? theme.palette.primary.main
            : theme.palette.secondary[800],
        '&:hover': {
          background: theme.palette.secondary[100],
        },
      },
      [BashButtonType.CLEAR_RED]: {
        ...baseStyles,
        color: theme.palette.red.main,
        '&:hover': {
          background: theme.palette.red[100],
        },
      },
      [BashButtonType.GREY]: {
        ...baseStyles,
        background: theme.palette.grey[150],
        color: theme.palette.text.primary,
        '&:hover': {
          background: theme.palette.grey[150],
        },
      },
      [BashButtonType.GREY_LIGHT]: {
        ...baseStyles,
        background: theme.palette.grey[150],
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.grey[200]}`,
        '&:hover': {
          background: theme.palette.grey[200],
        },
      },
      [BashButtonType.WHITE_GREY_BORDER]: {
        ...baseStyles,
        background:
          theme.palette.mode === 'dark'
            ? theme.palette.background.default
            : theme.palette.background.paper,
        color:
          theme.extras.name === 'custom'
            ? theme.palette.text.primary
            : theme.palette.grey[800],
        border: `1px solid ${theme.palette.grey[200]}`,
        '&:hover': {
          background: theme.palette.grey[150],
        },
      },
      [BashButtonType.WHITE_SECONDARY]: {
        ...baseStyles,
        background: theme.palette.background.paper,
        color: theme.palette.secondary.main,
        '&:hover': {
          background: theme.palette.secondary[200],
        },
      },
      [BashButtonType.WHITE_PRIMARY_BORDERED]: {
        ...baseStyles,
        background: theme.palette.background.paper,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        '&:hover': {
          background: theme.palette.primary[100],
        },
      },
      [BashButtonType.WHITE_RED_BORDERED]: {
        ...baseStyles,
        background: theme.palette.background.paper,
        color:
          theme.palette.mode === 'dark' ? theme.palette.red.main : '#EE6C4D',
        border: `1px solid ${theme.palette.mode === 'dark' ? theme.palette.red.main : '#EE6C4D'}`,
        '&:hover': {
          background:
            theme.palette.mode === 'dark' ? theme.palette.red[100] : '#FFF2F0',
        },
      },
      [BashButtonType.HALF_TRANSPARENT]: {
        ...baseStyles,
        background: '#11111150',
        color: 'white',
        '&:hover': {
          background: '#111111',
        },
      },
    }

    return styleMap[type]
  }

export const enum BashButtonType {
  PRIMARY = 'PRIMARY',
  PRIMARY_NO_THEME = 'PRIMARY_NO_THEME',
  PRIMARY_LIGHT = 'PRIMARY_LIGHT',
  PRIMARY_LIGHT_NO_THEME = 'PRIMARY_LIGHT_NO_THEME',
  PRIMARY_LIGHT_BORDERED = 'PRIMARY_LIGHT_BORDERED',
  SECONDARY = 'SECONDARY',
  SECONDARY_LIGHT = 'SECONDARY_LIGHT',
  CLEAR = 'CLEAR',
  CLEAR_GREY = 'CLEAR_GREY',
  CLEAR_PRIMARY = 'CLEAR_PRIMARY',
  CLEAR_SECONDARY = 'CLEAR_SECONDARY',
  CLEAR_RED = 'CLEAR_RED',
  GREY = 'GREY',
  GREY_LIGHT = 'GREY_LIGHT',
  WHITE_GREY_BORDER = 'WHITE_GREY_BORDER',
  WHITE_PRIMARY_BORDERED = 'WHITE_PRIMARY_BORDERED',
  WHITE_RED_BORDERED = 'WHITE_RED_BORDERED',
  WHITE_SECONDARY = 'WHITE_SECONDARY',
  HALF_TRANSPARENT = 'HALF_TRANSPARENT',
  SECONDARY_DARK = 'SECONDARY_DARK',
}

export const enum ButtonViewAlignment {
  ALIGN_VIEW_END = 'ALIGN_VIEW_END',
  ALIGN_VIEW_START = 'ALIGN_VIEW_START',
  ALIGN_TEXT_END = 'ALIGN_TEXT_END',
  ALIGN_TEXT_START = 'ALIGN_TEXT_START',
}

export interface BashButtonProps {
  type: BashButtonType
  extraView?: any | null
  viewAlignment?: ButtonViewAlignment
  loading?: boolean
  enabled?: boolean
  onClick: MouseEventHandler | undefined
  children: any
  className?: string | undefined
  href?: string | null
  target?: string | null
  rel?: string | null
  height?: 48 | 40 | 32
  disableHover?: boolean
  sx?: SxProps<Theme>
}

function getDisplayName(Component) {
  if (!Component) return 'Unknown'
  return (
    Component.displayName ||
    Component.name ||
    (typeof Component === 'string' && Component.length > 0
      ? Component
      : 'Unknown')
  )
}

// @ts-ignore
OpenInNew.displayName = 'OpenInNew'
// @ts-ignore
OpenInNewRounded.displayName = 'OpenInNewRounded'
// @ts-ignore
OpenInNewOutlined.displayName = 'OpenInNewOutlined'
// @ts-ignore
ChevronRight.displayName = 'ChevronRight'
// @ts-ignore
ChevronLeft.displayName = 'ChevronLeft'

const BashButton = ({
  type = BashButtonType.PRIMARY,
  extraView = null,
  viewAlignment = ButtonViewAlignment.ALIGN_VIEW_END,
  loading = false,
  enabled = true,
  onClick = () => {},
  children,
  className = undefined,
  href = null,
  height = 40,
  sx = [],
  disableHover = false,
  ...props
}: BashButtonProps) => {
  const theme = useTheme()

  const extraIsChevron = getDisplayName(extraView?.type?.type?.render)
    ?.toLowerCase()
    ?.includes('chevron')

  const makeIconSmaller = getDisplayName(extraView?.type?.type?.render)
    ?.toLowerCase()
    ?.includes('openinnew')

  const getExtraView = () => {
    if (extraView == null) return null
    else
      return loading ? (
        <CircularProgress
          size={20}
          sx={(theme) => ({
            color: 'inherit',
            margin: theme.spacing(0, 0.5),
          })}
        />
      ) : (
        extraView
      )
  }

  const extraViewToShow = getExtraView()

  const disabled = !enabled || loading

  const getType = () => {
    if (theme.extras?.name === 'custom') {
      // if (type === BashButtonType.GREY) return BashButtonType.PRIMARY_LIGHT
      if (type === BashButtonType.SECONDARY_LIGHT) return BashButtonType.PRIMARY
      if (type === BashButtonType.SECONDARY) return BashButtonType.PRIMARY
      return type
    } else {
      return type
    }
  }

  return (
    <Button
      // @ts-expect-error href works
      href={href}
      className={className}
      onClick={onClick}
      sx={[
        (theme) => ({
          flex: 'none',
          fontSize: '14px',
          borderRadius: 100,
          py: 1,
          px: height === 32 ? 1.5 : 2,
          minWidth: 90,
          height: height,
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          padding: theme.spacing(1, 2),
          position: 'relative',
          '& svg': {
            width: makeIconSmaller ? 16 : extraIsChevron ? 24 : 20,
            height: makeIconSmaller ? 16 : extraIsChevron ? 24 : 20,
          },
        }),
        !disabled && getButtonStyles(getType()),
        disabled &&
          ((theme) => ({
            pointerEvents: 'none',
            background:
              (theme.extras?.name === 'custom'
                ? theme.palette.grey[400]
                : theme.palette.grey[400]) + ' !important',
            color: 'white',
            '&:hover': {
              background: theme.palette.grey[400],
            },
          })),
        disableHover && {
          '&:hover': {
            background: 'inherit !important',
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
      disableRipple={disableHover}
    >
      {/* VIEW_START */}
      {extraViewToShow != null &&
        viewAlignment === ButtonViewAlignment.ALIGN_VIEW_START && (
          <Box
            sx={(theme) => ({
              position: 'absolute',
              display: 'flex',
              top: '50%',
              left: extraIsChevron ? '10px' : theme.spacing(2),
              transform: 'translateY(-50%)',
              color: 'inherit',
            })}
          >
            {extraViewToShow}
          </Box>
        )}
      {/* TEXT_START */}
      {extraViewToShow != null &&
        viewAlignment === ButtonViewAlignment.ALIGN_TEXT_START && (
          <Box
            sx={{
              display: 'flex',
              ml: extraIsChevron ? '-6px' : 0,
            }}
          >
            {extraViewToShow}
          </Box>
        )}
      {children && (
        <Typography
          style={{
            opacity: extraView == null && loading ? 0 : 1,
            transition: 'opacity 0.2 ease',
            fontWeight: [48, 40].includes(height) ? '600' : 500,
          }}
          variant={[48, 40].includes(height) ? 'button' : 'caption'}
        >
          {children}
        </Typography>
      )}
      {extraView == null && loading && (
        <Box
          sx={{
            display: 'flex',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <CircularProgress
            size={20}
            sx={(theme) => ({
              color: 'inherit',
              margin: theme.spacing(0, 0.5),
            })}
          />
        </Box>
      )}
      {/* TEXT_END */}
      {extraViewToShow != null &&
        viewAlignment === ButtonViewAlignment.ALIGN_TEXT_END && (
          <Box
            sx={{
              display: 'flex',
              mr: extraIsChevron ? '-6px' : 0,
            }}
          >
            {extraViewToShow}
          </Box>
        )}
      {/* VIEW_END */}
      {extraViewToShow != null &&
        viewAlignment == ButtonViewAlignment.ALIGN_VIEW_END && (
          <Box
            sx={(theme) => ({
              position: 'absolute',
              display: 'flex',
              top: '50%',
              right: extraIsChevron ? '10px' : theme.spacing(2),
              transform: 'translateY(-50%)',
              color: 'inherit',
            })}
          >
            {extraViewToShow}
          </Box>
        )}
    </Button>
  )
}

export default BashButton
