import React, { useEffect, useState } from 'react'
import { Box, Modal, Typography } from '@mui/material'
import Link from 'next/link'

import mixpanel from 'mixpanel-browser'
import { makeStyles } from 'tss-react/mui'
import BashButton, {
  BashButtonType,
} from '../../shared-components/buttons/BashButton'

const useStyles = makeStyles()((theme) => ({
  box: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    background: theme.palette.background.paper,
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: theme.spacing(4),
    color: theme.palette.mode === 'dark' ? '#FAFAFA' : '#4D4D4D',
  },
  accept: {
    height: theme.spacing(4),
    fontSize: 12,
  },
  denied: {
    height: theme.spacing(4),
    fontSize: 12,
    border: 'none',
  },
}))

export default function CookieModal() {
  const [displayPopUp, setDisplayPopUp] = useState(false)
  const { classes } = useStyles()

  useEffect(() => {
    const seenPopUp =
      localStorage.getItem('cookies') === 'true' ||
      localStorage.getItem('cookies') === 'false'
    setDisplayPopUp(!seenPopUp)
  }, [])

  const handleAcceptCookies = () => {
    mixpanel.opt_in_tracking()
    localStorage.setItem('cookies', 'true')
    window.dispatchEvent(new Event('storage'))
    setDisplayPopUp(false)
  }

  const handleRejectCookies = () => {
    localStorage.setItem('cookies', 'false')
    window.dispatchEvent(new Event('storage'))
    setDisplayPopUp(false)
  }

  return (
    <div>
      {displayPopUp && (
        <Modal
          style={{ zIndex: 99992 }}
          open={true}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box className={classes.box}>
            <Box
              sx={(theme) => ({
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: { xs: 'stretch', md: 'center' },
                maxWidth: theme.breakpoints.values.xl,
                width: '100%',
                gap: theme.spacing(3),
                margin: '0 auto',
                px: theme.spacing(2),
              })}
            >
              <Typography
                variant='caption'
                id='modal-modal-description'
                style={{
                  lineHeight: '14.32px',
                  flexGrow: 1,
                  flexShrink: 1,
                }}
              >
                BASH uses functional, analytical, cookies to improve the quality
                and effectiveness of our website. With your consent we also use
                these cookies for other purposes. Read our{' '}
                <strong>
                  <Link href='/cookienotice'>Cookie Notice</Link>
                </strong>{' '}
                and{' '}
                <strong>
                  <Link href='/privacy'>Privacy Policy</Link>
                </strong>
                .
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  flexShrink: 0,
                  gap: 1.5,
                }}
              >
                <BashButton
                  type={BashButtonType.PRIMARY_LIGHT_NO_THEME}
                  className={classes.denied}
                  onClick={handleRejectCookies}
                  variant='outlined'
                >
                  Accept essential cookies only
                </BashButton>
                <BashButton
                  type={BashButtonType.PRIMARY_NO_THEME}
                  className={classes.accept}
                  onClick={handleAcceptCookies}
                >
                  Accept all cookies
                </BashButton>
              </Box>
            </Box>
          </Box>
        </Modal>
      )}
    </div>
  )
}
